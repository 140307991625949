<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Социальные сети"
        :counter="$store.getters.getSocialNetwork.length"
        :button="{ title: 'Добавить социальную сеть', link: '/admin/social_network/create' }"
      />
      <main>
        <table>
          <tr>
            <td><p>Название документа</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in $store.getters.getSocialNetwork" :key="`country${item.id}`">
            <td><p>{{ item.link }}</p></td>
            <td  class="drop-icon">
              <drop-list @clickItem="clickItem" :idItem="item.id" :edit-link="`social_network/${item.id}/edit`"/>
            </td>
          </tr>
        </table>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'SocialNetwork',
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить социальную сеть?')
        if (consent) {
          this.$store.dispatch('deleteSocialNetwork', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
      }
    }
  },
  mounted () {
    this.$store.dispatch('setSocialNetwork')
  },
  components: {
    HeaderAdminPages,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
  cursor: pointer;
}
</style>
